import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import classNames from 'classnames';

const SectionHeader = ({
  title,
  url,
  urlTitle,
  rightBlock,
  additionalClasses,
  headlineType,
  asLink,
  cypress
}) => {
  const allClasses = classNames('section-header', additionalClasses);
  const Headline = `${headlineType}`;
  const SectionLink = url && (
    <Link as={asLink} href={url}>
      <a target="_self" className="show-all" data-cy="show-all-link">
        {urlTitle}
      </a>
    </Link>
  );

  return (
    <div className={allClasses}>
      <Headline className="thumblist-title" data-cy={cypress}>
        {title}
      </Headline>
      {rightBlock || SectionLink}
    </div>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  asLink: PropTypes.string,
  rightBlock: PropTypes.object,
  urlTitle: PropTypes.string,
  additionalClasses: PropTypes.string,
  headlineType: PropTypes.string,
  cypress: PropTypes.string
};

SectionHeader.defaultProps = {
  headlineType: 'h4'
};

export default SectionHeader;
